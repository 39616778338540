.news-list {
    overflow: hidden;
    padding-bottom: px2rem(50px);
    li {
        position: relative;
        margin-bottom: px2rem(36px);
        background-color: #f5f5f5;
        transition: all .2s ease;
        &:after {
            position: absolute;
            width: 0;
            height: 2px;
            left: 0;
            bottom: 0;
            background-color: $mainBlue;
            content: '';
            display: block;
            z-index: 2;
            transition: all .2s ease;
        }
        a {
            padding: px2rem(30px);
            background-color: #fafafa;
            transition: all .2s ease;
            overflow: hidden;
            display: block;
        }
        .img {
            float: left;
            width: 252px;
            height: 188px;
            position: relative;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
            + .info {
                margin-left: px2rem(295px);
            }
        }
        .info {
            margin-right: px2rem(15px);
            h3 {
                font-size: 20px;
                color: #000;
                padding-top: 10px;
                height: 36px;
                line-height: 36px;
                overflow: hidden;
                margin-bottom: 10px;
            }
        }
        .p {
            font-size: 15px;
            color: #676767;
            line-height: 24px;
            height: 48px;
            overflow: hidden;
            margin-bottom: 20px;
        }
        .time {
            font-size: 12px;
            font-family: 'Arial';
            color: #000;
            line-height: 26px;
            i {
                position: relative;
                float: right;
                width: 30px;
                height: 30px;
                line-height: 30px;
                color: #fff;
                font-style: normal;
                background-color: #999;
                font-size: 14px;
                display: block;
                border-radius: 100%;
                text-align: center;
                transform: rotate(180deg);
            }
        }
        &:hover {
            &:after {
                width: 100%;
            }
            a {
                background-color: #f3f8fc;
            }
            .time {
                i {
                    background-color: $mainBlue;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        li {
            .img {
                width: px2rem(252px);
                height: px2rem(188px);
                + .info {
                    margin-left: px2rem(275px);
                }
            }
            .info {
                h3 {
                    font-size: 16px;
                    line-height: 28px;
                    max-height: 56px;
                    height: auto;
                }
            }
            .p {
                display: none;
            }
            .time {
                i {
                    display: none;
                }
                span {
                    color: #999;
                }
            }
        }
    }
}
.news-detail {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 1100px) {
        width: auto;
        margin: 0 px2rem(50px);
    }
    &-top {
        border-bottom: 1px dashed #d0d0d0;
        padding-bottom: px2rem(40px);
        margin-bottom: px2rem(40px);
        text-align: center;
        .t {
            font-size: 28px;
            color: #333;
            margin-bottom: px2rem(20px);
        }
        .time {
            font-size: 14px;
            color: #999;
        }
    }
    &-article {
        line-height: 35px;
        color: #666;
        padding-bottom: px2rem(30px);
        @include articleReset(16px, 35px);
    }
    &-page {
        padding-top: px2rem(50px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px dashed #d0d0d0;
        ol {
            flex: 1;
            overflow: hidden;
            li {
                margin-bottom: 10px;
                a {
                    font-size: 14px;
                    line-height: 30px;
                    color: #666;
                    &:hover {
                        color: $mainBlue;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .back-btn {
            display: block;
            width: 180px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            color: #fff;
            border-radius: 4px;
            transition: all .2s ease;
            font-size: 14px;
            background-color: #434343;
            &:hover {
                background-color: $mainBlue;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-top {
            .t {
                font-size: 20px;
            }
        }
        &-article {
            font-size: 14px;
            line-height: 30px;
        }
        &-page {
            display: block;
            .back-btn {
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}