.index-banner {
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 800px) {
        display: none;
    }
}
.index-banner-mobile {
    display: none;
    margin-top: px2rem(100px);
    .swiper-slide {
        a {
            display: block;
            width: 100%;
            position: relative;
            padding-top: (480/750) * 100%;
            overflow: hidden;
            @include imgP();
        }
    }
    .swiper-pagination {
        &-bullet {
            opacity: 1;
            background-color: #fff;
            outline: none;
            &-active {
                background-color: $mainBlue;
            }
        }
    }
    @media screen and (max-width: 800px) {
        display: block;
    }
}
.index-banner-big {
    .swiper-slide {
        .img {
            display: block;
            width: 100%;
            padding-top: (900/1920) * 100%;
            position: relative;
            @include imgP();
        }
    }
}
.index-banner-pagination {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    background-color: #fff;
    width: calc(50% - 720px + 490px);
    text-align: right;
    height: 93px;
    padding-top: 23px;
    box-sizing: border-box;
    &:after {
        position: absolute;
        top: 0;
        right: -113px;
        width: 113px;
        height: 93px;
        background-image: url(../images/pagination_bg.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        content: '';
    }
    @media screen and (max-width: 1500px) {
        width: 520px;
    }
}
.index-pagination-swiper {
    display: inline-block;
    vertical-align: top;
    padding-right: 20px;
    position: relative;
    z-index: 10;
    .swiper-container {
        width: 470px;
        .swiper-slide {
            position: relative;
            height: 70px;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &-thumb-active {
                &:after {
                    position: absolute;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    content: '';
                    display: block;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    border: 3px solid $mainBlue;
                    background-color: rgba($mainBlue, .5);
                }
            }
        }
    }
    .index-pagination-prev,
    .index-pagination-next {
        position: absolute;
        top: 50%;
        font-size: 16px;
        color: #333;
        margin-top: -10px;
        cursor: pointer;
        outline: none;
        user-select: none;
        &:hover {
            color: $mainBlue;
        }
    }
    .index-pagination-prev {
        left: -20px;
    }
    .index-pagination-next {
        right: -10px;
        transform: rotate(180deg);
    }
}
.index-product {
    padding: px2rem(100px) 0 px2rem(80px);
    background-color: #fff;
    overflow: hidden;
    .title {
        text-align: center;
        font-size: px2rem(40px);
        color: #111725;
        margin-bottom: px2rem(40px);
    }
    @media screen and (max-width: 750px) {
        padding-top: px2rem(70px);
    }
}
.index-product-top {
    display: flex;
    background-color: #f5f5f5;
    margin-bottom: px2rem(24px);
    .img {
        width: 50%;
        display: block;
        span {
            display: block;
            width: 100%;
            padding-top: (492/720) * 100%;
            overflow: hidden;
            position: relative;
            @include imgP();
            &:hover {
                img {
                    transform: scale(1.1, 1.1);
                }
            }
        }
    }
    .info {
        flex: 1;
        display: flex;
        align-items: center;
        .wp {
            padding: 0 (100/720) * 100%;
        }
        h2 {
            font-size: px2rem(32px);
            margin-bottom: px2rem(25px);
            a {
                color: #333;
                &:hover {
                    color: $mainBlue;
                }
            }
        }
        .p {
            font-size: 14px;
            line-height: 30px;
            color: #999;
            margin-bottom: 30px;
        }
        .arrow {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            text-align: center;
            line-height: 30px;
            color: #fff;
            background-color: $mainBlue;
            display: block;
            font-size: 14px;
            transform: rotate(180deg);
            &:hover {
                background-color: lighten($mainBlue, 5%);
            }
        }
    }
    @media screen and (max-width: 800px) {
        display: block;
        .img {
            width: 100%;
        }
        .info {
            .wp {
                padding: px2rem(60px);
            }
        }
    }
    @media screen and (max-width: 750px) {
        .info {
            .p {
                font-size: 12px;
                line-height: 24px;
            }
        }
    }
}
.index-product-list {
    margin-left: -16px;
    font-size: 0;
    li {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        box-sizing: border-box;
        padding-left: 16px;
        overflow: hidden;
        margin-bottom: px2rem(24px);
        a {
            display: block;
            background-color: #f5f5f5;
            transition: all .4s ease;
            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, .2);
                transform: translateY(-5px);
                .img {
                    img {
                        transform: scale(1.1, 1.1);
                    }
                }
                .info {
                    .arrow {
                        background-color: $mainBlue;
                    }
                }
            }
        }
        .img {
            width: 100%;
            padding-top: (317/464) * 100%;
            position: relative;
            overflow: hidden;
            @include imgP();
        }
        .info {
            height: px2rem(90px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 px2rem(20px);
            .t {
                flex: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 18px;
                color: #333;
            }
            .arrow {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                text-align: center;
                line-height: 30px;
                color: #fff;
                background-color: #999;
                display: block;
                font-size: 14px;
                transform: rotate(180deg);
            }
        }
    }
    @media screen and (max-width: 800px) {
        margin-left: 0;
        li {
            width: 50%;
            margin-bottom: px2rem(30px);
            &:nth-child(2n+1) {
                padding: 0 px2rem(15px) 0 0;
            }
            &:nth-child(2n) {
                padding: 0 0 0 px2rem(15px);
            }
        }
    }
    @media screen and (max-width: 750px) {
        li {
            .info {
                .t {
                    font-size: 12px;
                }
                .arrow {
                    width: 16px;
                    height: 16px;
                    font-size: 10px;
                    line-height: 16px;
                }
            }
        }
    }
}
.index-about {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: px2rem(170px);
    padding-right: px2rem(50px);
    padding-left: px2rem(50px);
    overflow: hidden;
    &-info {
        text-align: center;
        width: 820px;
        max-width: 100%;
        color: #fff;
        margin: 0 auto px2rem(160px);
        .title {
            font-size: px2rem(40px);
            margin-bottom: px2rem(36px);
        }
        .p {
            font-size: 14px;
            line-height: 30px;
            margin-bottom: px2rem(40px);
            @media screen and (max-width: 750px) {
                font-size: 12px;
                line-height: 24px;
            }
        }
        .more {
            display: block;
            margin: 0 auto;
            border: 1px solid #fff;
            width: 110px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            transition: all .2s ease;
            color: #fff;
            &:hover {
                border-color: $mainBlue;
                background-color: $mainBlue;
            }
        }
    }
}
.index-about-list {
    font-size: 0;
    text-align: center;
    li {
        display: inline-block;
        vertical-align: top;
        margin: 0 1px;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(#2e9cf2, .9);
            width: 190px;
            height: 190px;
            color: #fff;
            flex-direction: column;
            transition: all .2s ease;
            &:hover {
                background-color: $mainBlue;
                .img {
                    transform: translateY(-5px);
                }
            }
            @media screen and (max-width: 1024px) {
                width: 170px;
            }
        }
        .img {
            width: 100px;
            height: 100px;
            margin-bottom: 12px;
            transition: all .2s ease;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .t {
            font-size: 16px;
            @media screen and (max-width: 750px) {
                font-size: 12px;
            }
            @media screen and (max-width: 400px) {
                font-size: 10px;
            }
        }
        &:nth-child(2) {
            transition: all .4s .4s linear;
        }
        &:nth-child(3) {
            transition: all .4s .6s linear;
        }
        &:nth-child(4) {
            transition: all .4s .8s linear;
        }
        &:nth-child(5) {
            transition: all .4s 1s linear;
        }
    }
    @media screen and (max-width: 800px) {
        display: flex;
        li {
            flex: 1;
            a {
                width: 100%;
                height: auto;
                padding: px2rem(20px);
                box-sizing: border-box;
            }
            .img {
                width: 100%;
                height: auto;
            }
        }
    }
}
.index-news {
    padding: px2rem(90px) 0 px2rem(100px);
    .title {
        text-align: center;
        margin-bottom: px2rem(40px);
        color: #333;
        font-size: px2rem(40px);
    }
    &-swiper {
        position: relative;
        .index-news-prev,
        .index-news-next {
            position: absolute;
            font-size: 36px;
            cursor: pointer;
            outline: none;
            color: #333;
            top: 50%;
            line-height: 40px;
            margin-top: -130px;
            z-index: 5;
            &:hover {
                color: $mainBlue;
            }
        }
        .index-news-prev {
            left: -50px;
        }
        .index-news-next {
            right: -50px;
            transform: rotate(180deg);
        }
        @media screen and (max-width: 800px) {
            .index-news-prev {
                left: px2rem(-30px);
            }
            .index-news-next {
                right: px2rem(-30px);
            }
        }
        @media screen and (max-width: 480px) {
            .index-news-prev,
            .index-news-next {
                display: none;
            }
        }
    }
    .swiper-container {
        .swiper-slide {
            a {
                display: block;
                &:hover {
                    .img img {
                        transform: scale(1.1, 1.1);
                    }
                    .t {
                        color: $mainBlue;
                    }
                    .more {
                        border-color: $mainBlue;
                        background-color: $mainBlue;
                        color: #fff;
                    }
                }
            }
        }
        .img {
            width: 100%;
            padding-top: (336/450) * 100%;
            position: relative;
            overflow: hidden;
            @include imgP();
        }
        .info {
            padding: px2rem(24px) px2rem(10px);
        }
        .t {
            font-size: 18px;
            font-weight: bold;
            color: #333;
            @include toe();
        }
        .time {
            font-size: 14px;
            color: #999;
        }
        .p {
            font-size: 14px;
            line-height: 24px;
            color: #666;
            margin-top: px2rem(15px);
            height: 48px;
            overflow: hidden;
        }
        .more {
            width: 110px;
            height: 40px;
            border: 1px solid #555;
            color: #333;
            text-align: center;
            line-height: 40px;
            font-size: 14px;
            transition: all .2s ease;
            margin-top: px2rem(24px);
        }
        .swiper-pagination {
            position: static;
            display: none;
            @media screen and (max-width: 480px) {
                display: block;
            }
        }
    }
}
.index-case {
    display: flex;
    background-color: #f5f5f5;
    align-items: center;
    @media screen and (max-width: 800px) {
        display: block;
    }
}
.index-case-info {
    flex: 660;
    overflow: hidden;
    .box {
        margin-left: calc(50vw - 720px);
        width: 320px;
        max-width: 100%;
        @media screen and (max-width: 1540px) {
            margin-left: px2rem(50px);
            max-width: calc(100% - .25rem);
        }
    }
    .en {
        text-transform: uppercase;
        font-size: px2rem(40px);
        font-family: 'DIN';
        line-height: px2rem(46px);
    }
    .cn {
        font-size: px2rem(30px);
        color: #333;
    }
    .p {
        font-size: 14px;
        line-height: 30px;
        color: #666;
        margin-top: px2rem(30px);
    }
    .more {
        display: block;
        width: 110px;
        height: 40px;
        border: 1px solid #333;
        font-size: 14px;
        color: #333;
        transition: all .2s ease;
        margin-top: px2rem(50px);
        text-align: center;
        line-height: 40px;
        &:hover {
            border-color: $mainBlue;
            color: #fff;
            background-color: $mainBlue;
        }
    }
    @media screen and (max-width: 800px) {
        .box {
            margin-left: 0;
            max-width: 100%;
            padding: px2rem(80px) px2rem(30px);
            box-sizing: border-box;
            width: 100%;
        }
    }
}
.index-case-list {
    flex: 1260;
    overflow: hidden;
    .swiper-slide {
        flex: 1;
        width: auto;
        height: px2rem(780px);
        transition: all .4s ease;
        backface-visibility: hidden;
        a {
            display: block;
            width: 100%;
            height: 100%;
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;
            backface-visibility: hidden;
        }
        &:hover {
            flex: 1.9;
        }
        .title {
            padding-top: px2rem(120px);
            text-align: center;
            color: #fff;
            line-height: 32px;
        }
        .en {
            font-size: 25px;
            font-family: 'DIN';
            text-transform: uppercase;
        }
        .cn {
            font-size: 18px;
        }
    }
    @media screen and (max-width: 800px) {
        .swiper-slide {
            flex: 0 0 auto;
            width: px2rem(420px) !important;
        }
    }
}
.inside-banner {
    width: 100%;
    margin-top: px2rem(110px);
    img {
        display: block;
        width: 100%;
    }
    @media screen and (max-width: 1220px) {
        margin-top: 0;
    }
    @media screen and (max-width: 800px) {
        display: none;
    }
}
.inside-banner-mobile {
    margin-top: px2rem(100px);
    width: 100%;
    padding-top: (320/750) * 100%;
    position: relative;
    overflow: hidden;
    display: none;
    @include imgP();
    @media screen and (max-width: 800px) {
        display: block;
    }
}