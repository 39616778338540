.case-list {
    margin-left: -30px;
    font-size: 0;
    padding-bottom: px2rem(50px);
    li {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        box-sizing: border-box;
        padding-left: 30px;
        overflow: hidden;
        margin-bottom: px2rem(30px);
        a {
            display: block;
            background-color: #f5f5f5;
            transition: all .4s ease;
            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, .2);
                transform: translateY(-5px);
                .img {
                    img {
                        transform: scale(1.1, 1.1);
                    }
                }
                .info {
                    .arrow {
                        background-color: $mainBlue;
                    }
                }
            }
        }
        .img {
            width: 100%;
            padding-top: (317/464) * 100%;
            position: relative;
            overflow: hidden;
            @include imgP();
        }
        .info {
            height: px2rem(70px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 px2rem(20px);
            .t {
                flex: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 18px;
                color: #333;
            }
            .arrow {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                text-align: center;
                line-height: 30px;
                color: #fff;
                background-color: #999;
                display: block;
                font-size: 14px;
                transform: rotate(180deg);
            }
        }
    }
    @media screen and (max-width: 750px) {
        margin-left: 0;
        li {
            width: 50%;
            box-sizing: border-box;
            &:nth-child(2n) {
                padding: 0 0 0 px2rem(10px);
            }
            &:nth-child(2n+1) {
                padding: 0 px2rem(10px) 0 0;
            }
            .info {
                .t {
                    font-size: 12px;
                }
                .arrow {
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 10px;
                }
            }
        }
    }
}
.case-detail-top {
    background-color: #f5f5f5;
    padding: px2rem(20px);
    margin-bottom: px2rem(70px);
    box-sizing: border-box;
    display: flex;
    @media screen and (max-width: 1000px) {
        display: block;
    }
}
.case-detail-swiper {
    flex: 760;
    overflow: hidden;
}
.case-detail-big-swiper {
    width: 100%;
    margin-bottom: 10px;
    .swiper-slide {
        width: 100%;
        height: 0;
        padding-top: (517/760) * 100%;
        position: relative;
        overflow: hidden;
        @include imgP();
    }
}
.case-detail-thumb-swiper {
    padding: 0 px2rem(85px);
    position: relative;
    .case-detail-thumb-prev,
    .case-detail-thumb-next {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        cursor: pointer;
        text-align: center;
        line-height: 30px;
        color: #fff;
        font-size: 14px;
        position: absolute;
        top: 50%;
        margin-top: -15px;
        transition: all .2s ease;
        outline: none;
        background-color: #999;
        &:hover {
            background-color: $mainBlue;
        }
    }
    .case-detail-thumb-prev {
        left: px2rem(14px);
    }
    .case-detail-thumb-next {
        right: px2rem(14px);
        transform: rotate(180deg);
    }
    .swiper-slide {
        width: auto;
        cursor: pointer;
        position: relative;
        img {
            width: px2rem(140px);
            height: px2rem(94px);
        }
        &-thumb-active {
            &:after {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 2;
                background-color: rgba($mainBlue, .4);
                content: '';
            }
        }
    }
}
.case-detail-top-article {
    flex: 390;
    overflow: hidden;
    padding-top: px2rem(40px);
    .box {
        padding-left: px2rem(60px);
        padding-right: px2rem(20px);
    }
    .t {
        font-size: 32px;
        color: #333;
        margin-bottom: px2rem(20px);
    }
    .article {
        font-size: 18px;
        line-height: 34px;
        color: #666;
        text-align: justify;
    }
    @media screen and (max-width: 1000px) {
        .box {
            padding-left: 0;
            padding-right: 0;
        }
    }
    @media screen and (max-width: 750px) {
        .t {
            font-size: 20px;
        }
        .article {
            font-size: 14px;
            line-height: 28px;
        }
    }
}
.case-detail-article {
    line-height: 36px;
    color: #333;
    overflow: hidden;
    @include articleReset(18px, 36px);
    .t {
        font-size: 20px;
        font-weight: bold;
        color: #333;
        margin-bottom: 15px;
    }
    @media screen and (max-width: 750px) {
        font-size: 14px;
        line-height: 28px;
    }
}