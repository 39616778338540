.honor-con {
    padding: px2rem(60px) 0 px2rem(100px);
    background-color: #fff;
}
.honor-page {
    padding: px2rem(60px) 0;
}
.honor-page-list {
    padding-bottom: 30px;
    > li {
        float: left;
        width: 25%;
    }
    .box {
        cursor: pointer;
        display: block;
        padding: 8px;
        margin: 0 8px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
    }
    .box-img {
        position: relative;
        padding-top: (200/270) * 100%;
        background: #f5f5f5;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -50%);
        }
    }
    .box-info {
        padding: 30px 20px 0;
    }
    .h4 {
        font-size: 16px;
        color: #000;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-bottom: 12px;
        border-bottom: 1px dashed #bcbcbc;
        margin-bottom: 10px;
    }
    .p {
        font-size: 12px;
        line-height: 24px;
        height: 72px;
        overflow: hidden;
        color: #777;
    }
}
@media(max-width: 1200px) {
    .honor-page {
        .wp1200 {
            max-width: 1000px;
        }
    }
    .honor-page-list {
        > li {
            width: (100%/3);
        }
    }
}
@media(max-width: 800px) {
    .honor-page-list {
        padding-bottom: 15px;
        > li {
            width: 50%;
        }
        .box {
            padding: 5px;
            margin: 0 5px 15px;
        }
        .box-info {
            padding: 10px 10px 0;
        }
        .h4 {
            font-size: 15px;
            padding-bottom: 8px;
            margin-bottom: 8px;
        }
        .p {
            height: 48px;
        }
    }
}
// 弹框
.honor-pop {
    display: none;
    position: fixed;
    z-index: 202;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
    .pop-con {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 920px;
        height: 800px;
        background-color: #fff;
        border-radius: 4px;
    }
    .box {
        padding: 70px 80px 0;
    }
    .box-img {
        position: relative;
        padding-top: (560/740) * 100%;
        background: #f5f5f3;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
            transform: translate(-50%, -50%);
        }
    }
    .h4 {
        display: block;
        padding: 20px 0;
        margin-bottom: 15px;
        border-bottom: 1px dashed #a6a6a6;
        font-size: 24px;
        color: #444;
    }
    .p {
        font-size: 14px;
        line-height: 28px;
        color: #777;
    }
    .pop-close {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: -60px;
        z-index: 1;
        width: 60px;
        height: 60px;
        line-height: 60px;
        background-color: $mainBlue;
        color: #fff;
        font-size: 30px;
        text-align: center;
        &:hover {
            opacity: .95;
        }
    }
}
@media screen and (max-height: 800px), (max-width: 950px) {
    .honor-pop {
        .pop-con {
            width: 100%;
            height: 100%;
            border-radius: 0;
        }
        .box {
            padding: 70px 10px 0;
        }
        .box-img {
            position: absolute;
            top: 70px;
            left: 10px;
            right: 10px;
            bottom: 150px;
            padding-top: 0;
            background-color: #fff;
        }
        .h4 {
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: 50px;
            font-size: px2rem(38px);
        }
        .p {
            position: absolute;
            bottom: 10px;
            left: 10px;
            right: 10px;
        }
        .pop-close {
            top: 0;
            right: 0;
            width: 45px;
            height: 45px;
            line-height: 45px;
            font-size: 22px;
        }
    }
}