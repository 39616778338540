@charset 'utf-8';
/* CSS Document */
//SCSS全局变量
$mainBlue: #007eff;
// 默认移动端设计稿宽度
$baseDevice: 750;
// 前端切图移动端默认正常显示尺寸，默认为设计稿的一半
$device: $baseDevice / 2;
// 默认html font-size
$baseFontSize: 100px;
// scss function
@function calc-percent($target, $context) {
    @return $target/$context * 100%;
}
@function px2rem($px, $base-font-size: $baseDevice / $device * $baseFontSize) {
    @if (unitless($px)) {
        @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels for you";
        @return px2rem($px + 0px); // That may fail.
    } @else if (unit($px)==rem) {
        @return $px;
    }
    @return ($px / $base-font-size) * 1rem;
}
//透明
@mixin touming($o: 70) {
    opacity: $o/100;
    -webkit-opacity: $o/100;
    filter: alpha(opacity=$o);
}
@mixin transition($time: .2s) {
    -webkit-transition: $time ease all;
    -moz-transition: $time ease all;
    transition: $time ease all;
}
@mixin articleReset($fontsize: 14px, $lineHieght: 30px) {
    font-size: $fontsize;
    p {
        margin-bottom: $lineHieght/2;
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
    ul {
        list-style: disc;
        li {
            list-style: disc;
        }
    }
    ol {
        list-style: decimal;
        li {
            list-style: decimal;
        }
    }
}
@mixin imgP() {
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .6s ease;
    }
}
@mixin toe() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
/* reset.css V1.6  Start*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ul,
ol,
li,
form,
button,
input,
textarea,
th,
td {
    font-weight: normal;
    margin: 0;
    padding: 0;
}
body,
button,
input,
select,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
i {
    font: normal 12px BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
ul,
ol,
li {
    list-style: none;
}
a {
    text-decoration: none;
    color: #333;
}
a:hover {
    text-decoration: none;
}
img {
    vertical-align: top;
    border: 0;
    user-select: none;
    -webkit-touch-callout: none;
    pointer-events: none;
}
button,
input,
textarea {
    font-size: 100%;
    vertical-align: middle;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
    float: none;
}
div,
a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    user-select: none;
    -webkit-touch-callout: none;
}
html {
    font-size: $baseFontSize;
    user-select: none;
    -webkit-touch-callout: none;
}
@media screen and (min-width: 320px) {
    html {
        font-size: (320/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 360px) {
    html {
        font-size: (360/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 375px) {
    html {
        font-size: (375/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 480px) {
    html {
        font-size: (480/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 640px) {
    html {
        font-size: (640/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 750px) {
    html {
        font-size: (750/$device) * $baseFontSize;
    }
}
.clearfloat:after {
    font-size: 0;
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ' ';
}
.clearfloat {
    *zoom: 1;
}
.clearboth {
    clear: both;
}
.wp1440 {
    width: 1440px;
    margin: 0 auto;
    @media screen and (max-width: 1540px) {
        width: auto;
        margin: 0 50px;
    }
    @media screen and (max-width: 1220px) {
        margin: 0 px2rem(30px);
    }
}
.wp1200 {
    width: 1200px;
    margin: 0 auto;
    @media screen and (max-width: 1300px) {
        width: auto;
        margin: 0 50px;
    }
    @media screen and (max-width: 1220px) {
        margin: 0 px2rem(30px);
    }
}
.checkBrowser {
    font-size: 14px;
    line-height: 40px;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 50%;
    width: 700px;
    height: 40px;
    margin-left: -360px;
    padding: 0 10px;
    color: #fff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #e6212a;
    a {
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        text-align: center;
        color: #333;
        border-radius: 50%;
        background-color: #fff;
    }
}
/* reset.css V1.6  End */
@font-face {
    font-family: 'DIN';
    src: url('../font/DIN-MEDIUMALTERNATE.eot');
    src: local('☺'), url('../font/DIN-MEDIUMALTERNATE.woff') format('woff'), url('../font/DIN-MEDIUMALTERNATE.ttf') format('truetype'), url('../font/DIN-MEDIUMALTERNATE.svg') format('svg');
}
// page结构以及CSS样式
// *******************
// ********************
// ********************
// HTML结构
// <div class="paged">
//  <a href="#">首页</a>
//  <a class="prev">上一页</a>
//  <span class="current">1</span>
//  <a href="#">2</a>
//  <a class="next" href="#">下一页</a>
//  <a href="#">尾页</a>
// </div>
// ********************
// ********************
// ********************
// CSS样式
.paged {
    //padding-top: 30px;
    text-align: center;
}
.paged a,
.paged .p_info,
.paged .current {
    display: inline-block;
    margin: 0 3px 2px;
    padding: 0 15px;
    font-size: 13px;
    text-align: center;
    border: 1px solid #c8cace;
    background: #fff;
    color: #8b8b8b;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
}
.paged .current,
.paged a:hover {
    border: 1px solid $mainBlue;
    background: $mainBlue;
    color: #fff;
}
@keyframes t2b {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.header {
    background-color: #fff;
    height: px2rem(110px);
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    &.anim {
        animation-name: t2b;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
    @media screen and (max-width: 1220px) {
        display: none;
    }
    .wp1440 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    .logo {
        height: 100%;
        a {
            display: block;
            height: 100%;
            img {
                max-height: 100%;
                max-width: 100%;
            }
        }
        @media screen and (max-width: 1440px) {
            width: 300px;
        }
    }
    &-fixed {
        width: 100%;
        background-color: #fff;
    }
    &-lang {
        height: px2rem(110px);
        line-height: px2rem(110px);
        font-size: 16px;
        font-weight: bold;
        color: $mainBlue;
        display: inline-block;
        vertical-align: top;
        margin-left: 20px;
        &:hover {
            color: lighten($mainBlue, 5%);
        }
    }
    &-search {
        display: inline-block;
        vertical-align: top;
        height: px2rem(110px);
        position: relative;
        cursor: pointer;
        margin-left: 25px;
        &:hover {
            .header-search-box {
                opacity: 1;
                visibility: visible;
            }
        }
        .icon {
            display: block;
            height: px2rem(110px);
            font-size: 22px;
            line-height: px2rem(110px);
            color: $mainBlue;
        }
        &-box {
            position: absolute;
            right: 0;
            top: 70px;
            display: flex;
            width: 240px;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, .1);
            opacity: 0;
            visibility: hidden;
            transition: all .2s ease;
            .input {
                flex: 1;
                overflow: hidden;
                input {
                    display: block;
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                    color: #333;
                    appearance: none;
                    border: none;
                    background: none;
                    box-sizing: border-box;
                    padding: 0 10px;
                }
            }
            button {
                display: block;
                width: 40px;
                height: 40px;
                background: none;
                appearance: none;
                text-align: center;
                line-height: 40px;
                color: $mainBlue;
                cursor: pointer;
                border: none;
            }
        }
    }
}
.mobile-header {
    display: none;
    height: px2rem(100px);
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    padding: 0 px2rem(30px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 889;
    &.show-menu {
        z-index: 9902;
    }
    .logo {
        a {
            display: block;
        }
        img {
            height: px2rem(60px);
        }
    }
    .mobile-header-icon {
        width: px2rem(60px);
        height: px2rem(60px);
        border-radius: 100%;
        cursor: pointer;
        background-color: $mainBlue;
        text-align: center;
        line-height: px2rem(60px);
        margin-left: px2rem(20px);
        font-size: px2rem(26px);
        color: #fff;
        display: inline-flex;
        align-items: center;
        vertical-align: top;
        justify-content: center;
    }
    .mobile-menu-btn {
        &:before {
            content: '\e604';
        }
        &.show-menu {
            &:before {
                content: '\e607';
            }
        }
    }
    @media screen and (max-width: 1220px) {
        display: flex;
    }
}
.nav {
    display: inline-block;
    vertical-align: top;
    li {
        display: inline-block;
        vertical-align: top;
        margin-left: 10px;
        position: relative;
        > a {
            height: px2rem(110px);
            display: flex;
            align-items: center;
            text-align: center;
            font-size: 16px;
            color: #333;
            span {
                display: block;
                padding: 0 10px;
                height: px2rem(50px);
                line-height: px2rem(50px);
                border-bottom: 3px solid transparent;
            }
        }
        &.on,
        &.hover {
            > a {
                color: $mainBlue;
                span {
                    border-bottom-color: $mainBlue;
                }
            }
        }
        .sub {
            position: absolute;
            width: 120px;
            top: px2rem(110px);
            left: 50%;
            margin-left: -60px;
            background-color: rgba(255, 255, 255, .8);
            display: none;
            .item {
                border-bottom: 1px solid #c2c6c9;
                a {
                    display: block;
                    padding: 14px 10px;
                    text-align: center;
                    font-size: 14px;
                    color: #333;
                    line-height: 24px;
                    transition: all .2s ease;
                    &:hover {
                        color: $mainBlue;
                    }
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
    @media screen and (max-width: 1330px) {
        li {
            margin-left: 3px;
            > a {
                font-size: 14px;
            }
        }
    }
}
.footer {
    background-color: #28292f;
}
.footer-link-con {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    padding: px2rem(30px) 0;
    .wp1440 {
        display: flex;
        justify-content: space-between;
    }
    @media screen and (max-width: 750px) {
        .wp1440 {
            display: block;
        }
    }
}
.footer-link {
    flex: 1;
    display: flex;
    overflow: hidden;
    padding-top: 10px;
    .word {
        color: #fff;
        font-size: 14px;
        line-height: 28px;
    }
    .box {
        flex: 1;
        overflow: hidden;
        a {
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
            line-height: 28px;
            margin-right: px2rem(20px);
            color: rgba(255, 255, 255, .5);
            &:hover {
                text-decoration: underline;
                color: #fff;
            }
        }
    }
}
.footer-share {
    .bdshare-button-style0-32 a {
        margin: 0 0 0 15px;
        width: 45px;
        height: 45px;
        border-radius: 100%;
        background: #e5e5e5;
        text-indent: 0;
        font-size: 22px;
        color: #7c7c7c;
        text-align: center;
        line-height: 45px;
    }
    @media screen and (max-width: 750px) {
        padding-top: px2rem(30px);
        .bdshare-button-style0-32 {
            text-align: center;
            a {
                display: inline-block;
                vertical-align: top;
                float: none;
                margin: 0 5px;
            }
        }
    }
}
.footer-copyright {
    padding: px2rem(30px) 0;
    .wp1440 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .ewm {
        text-align: center;
        img {
            width: 108px;
            height: 108px;
            display: block;
            margin: 0 auto 5px;
        }
        p {
            font-size: 14px;
            color: rgba(255, 255, 255, .5);
        }
    }
    .copyright {
        flex: 1;
        overflow: hidden;
        font-size: 14px;
        line-height: 30px;
        color: #fff;
        span {
            display: block;
        }
        a {
            color: #fff;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    @media screen and (max-width: 750px) {
        .wp1440 {
            display: block;
        }
        .copyright {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: px2rem(30px);
        }
    }
}
.right-fixed {
    position: fixed;
    right: 0;
    z-index: 201;
    top: px2rem(110px);
    @media screen and (max-width: 1024px) {
        display: none;
    }
    .item {
        position: relative;
        margin-bottom: 2px;
        &:hover {
            .box {
                border-color: $mainBlue;
                background-color: $mainBlue;
                .p {
                    color: #fff;
                }
                .iconfont {
                    color: #fff;
                }
            }
            .float-box {
                display: block;
            }
        }
        &:not(:first-child) {
            display: none;
        }
    }
    .box {
        border: 1px solid #e5e5e5;
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #f5f5f5;
        text-align: center;
        cursor: pointer;
        transition: all .2s ease;
        .p {
            font-size: 12px;
            color: #666;
        }
        .iconfont {
            font-size: 36px;
            color: #999;
            margin-bottom: 6px;
            transition: all .2s ease;
        }
    }
    .float-box {
        position: absolute;
        top: 0;
        right: 82px;
        background-color: rgba(255, 255, 255, .9);
        min-width: 220px;
        padding: 20px;
        display: none;
        box-sizing: border-box;
        .t {
            font-size: 16px;
            margin-bottom: 5px;
        }
        .phone {
            font-size: 24px;
            color: $mainBlue;
        }
        .ewm {
            img {
                max-width: 180px;
            }
        }
    }
    &:hover {
        .item {
            display: block;
            &:first-child {
                .iconfont {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
.inside-menu {
    //padding: 19px 0;
    background-color: #f5f5f5;
    border-bottom: 1px solid #d9d9d9;
    overflow: hidden;
    &-sub {
        border-bottom: 1px solid #d9d9d9;
        background-color: #fff;
        overflow: hidden;
    }
}
.inside-swiper {
    //height: 32px;
    width: 100%;
    overflow: hidden;
    background-color: #f5f5f5;
    .swiper-slide {
        display: inline-block;
        vertical-align: top;
        width: auto;
        a {
            padding: 0 px2rem(40px);
            position: relative;
            display: block;
            font-size: 16px;
            line-height: 70px;
            height: 70px;
            color: #333;
            transition: all .2s ease;
        }
        &.on,
        &:hover {
            a {
                color: $mainBlue;
                font-weight: bold;
            }
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        //transform: scale(.5);
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease;
        outline: none;
        &.swiper-button-disabled {
            pointer-events: auto;
        }
    }
    &:hover {
        .swiper-button-next,
        .swiper-button-prev {
            &.can-show {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    @media screen and (max-width: 750px) {
        .swiper-slide {
            a {
                font-size: 14px;
                line-height: 50px;
                height: 50px;
            }
        }
    }
}
.col-menu {
    text-align: center;
    a {
        display: inline-block;
        vertical-align: top;
        margin: 0 5px;
        width: 160px;
        height: 45px;
        text-align: center;
        line-height: 45px;
        border-radius: 5px;
        background-color: #eee;
        font-size: 16px;
        color: #333;
        transition: all .2s ease;
        &.on,
        &:hover {
            background-color: $mainBlue;
            color: #fff;
        }
    }
    @media screen and (max-width: 640px) {
        display: flex;
        a {
            flex: 1;
            height: 36px;
            line-height: 36px;
            font-size: 14px;
        }
    }
}
.coop-list {
    position: relative;
    margin-left: -18px;
    overflow: hidden;
    font-size: 0;
    padding-bottom: px2rem(50px);
    li {
        display: inline-block;
        vertical-align: top;
        box-sizing: border-box;
        padding-left: 18px;
        width: 20%;
        margin-bottom: px2rem(20px);
        a {
            display: block;
            position: relative;
            width: 100%;
            border: 1px solid #ddd;
            box-sizing: border-box;
        }
        .img {
            width: 100%;
            position: relative;
            height: 0;
            padding-top: (98/222) * 100%;
            background-color: #fff;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .p {
            position: absolute;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border: 2px solid $mainBlue;
            background-color: rgba($mainBlue, .8);
            transition: all .4s ease;
            opacity: 0;
            &:hover {
                opacity: 1;
            }
            h3 {
                font-size: 16px;
                color: #fff;
            }
        }
    }
    @media screen and (max-width: 800px) {
        margin-left: -10px;
        li {
            padding-left: 10px;
        }
    }
    @media screen and (max-width: 640px) {
        li {
            width: 33.33%;
        }
    }
    @media screen and (max-width: 450px) {
        li {
            width: 50%;
        }
    }
}
.service-list {
    .item {
        display: flex;
        margin-bottom: px2rem(60px);
        background-color: #f5f5f5;
        &:nth-child(2n) {
            flex-direction: row-reverse;
            .box {
                padding-right: px2rem(90px);
                padding-left: px2rem(50px);
            }
        }
    }
    .img {
        flex: 570;
        overflow: hidden;
        display: flex;
        span {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
            @include imgP();
        }
    }
    .info {
        flex: 630;
        overflow: hidden;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        min-height: 410px;
    }
    .box {
        padding: px2rem(30px) px2rem(50px) px2rem(30px) px2rem(90px);
    }
    .title {
        position: relative;
        padding-bottom: 18px;
        margin-bottom: px2rem(30px);
        &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            height: 2px;
            width: 42px;
            display: block;
            background: $mainBlue;
        }
        .en {
            color: $mainBlue;
            text-transform: uppercase;
            font-size: 50px;
            font-weight: bold;
            margin-bottom: px2rem(10px);
        }
        .cn {
            font-size: 24px;
            color: #333;
        }
    }
    .p {
        font-size: 18px;
        line-height: 40px;
        color: #666;
    }
    @media screen and (max-width: 800px) {
        .item {
            display: block;
        }
        .img {
            width: 100%;
            span {
                height: 0;
                padding-top: (412/570) * 100%;
            }
        }
        .info {
            min-height: 0;
            display: block;
        }
        .box {
            padding: px2rem(30px) !important;
        }
        .title {
            .en {
                font-size: 28px;
            }
            .cn {
                font-size: 20px;
            }
        }
        .p {
            font-size: 16px;
            line-height: 32px;
        }
    }
}
.nav-mobile {
    position: absolute;
    width: px2rem(530px);
    top: px2rem(170px);
    right: 0;
    bottom: 0;
    z-index: 103;
    background: #f5f5f5;
    box-sizing: border-box;
    overflow: hidden;
    transform: translateX(100%);
    transition: transform .4s ease;
    //&.anim {
    //    transform: translateX(0);
    //}
    &-con {
        display: none;
        position: fixed;
        width: 100%;
        top: px2rem(100px);
        left: 0;
        bottom: 0;
        z-index: 9902;
        box-sizing: border-box;
        overflow: hidden;
        .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .4);
            z-index: 102;
        }
        &.anim {
            .nav-mobile,
            .nav-mobile-search {
                transform: translateX(0);
            }
        }
    }
    &-top {
        width: 100%;
        height: px2rem(110px);
        line-height: px2rem(110px);
        box-sizing: border-box;
        padding: 0 px2rem(50px);
        background-color: #393939;
        font-size: px2rem(30px);
        color: #fff;
        z-index: 103;
        position: absolute;
        top: 0;
        left: 0;
    }
    &-search {
        position: absolute;
        right: 0;
        top: 0;
        background-color: #f5f5f5;
        height: px2rem(170px);
        display: flex;
        z-index: 104;
        box-sizing: border-box;
        border-bottom: 1px solid #e2e2e2;
        align-items: center;
        padding: 0 px2rem(50px);
        width: px2rem(530px);
        transition: transform .4s ease;
        transform: translateX(100%);
        //&.anim {
        //    transform: translateX(0);
        //}
        .box {
            //height: px2rem(90px);
            border: 1px solid #e2e2e2;
            border-radius: px2rem(45px);
            background-color: #fff;
            display: flex;
            padding: 0 px2rem(30px);
            width: 100%;
            box-sizing: border-box;
        }
        .input {
            flex: 1;
            overflow: hidden;
        }
        input[type="text"] {
            width: 100%;
            height: px2rem(90px);
            line-height: px2rem(90px);
            appearance: none;
            border: none;
            font-size: px2rem(26px);
            background: none;
        }
        button {
            width: px2rem(90px);
            height: px2rem(90px);
            border: none;
            appearance: none;
            //font-family: iconfont;
            display: block;
            background: none;
            font-size: px2rem(40px);
            color: #666;
            //&:before {
            //    content: '\e627';
            //}
        }
    }
    &-wp {
        position: relative;
    }
    &-title {
        position: relative;
        //margin: 0 px2rem(50px);
        border-bottom: 1px solid #e2e2e2;
        padding: 0 px2rem(30px) 0 px2rem(50px);
        > a {
            position: relative;
            display: block;
            line-height: px2rem(84px);
            font-size: px2rem(28px);
            color: #333;
            z-index: 2;
            backface-visibility: hidden;
            width: 100%;
        }
    }
    &-child-btn {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: right;
        line-height: px2rem(84px);
        font-size: px2rem(30px);
        font-weight: bold;
        z-index: 1;
        color: #848484;
        span {
            display: inline-block;
            margin-right: px2rem(40px);
        }
        i {
            position: absolute;
            display: block;
            width: px2rem(40px);
            height: 100%;
            top: 0;
            right: px2rem(30px);
            color: #888;
            line-height: px2rem(84px);
            text-align: center;
            font-size: px2rem(30px);
            //transform: rotate(180deg);
            transition: transform .2s ease;
            &:before {
                content: '+';
            }
        }
        &.open {
            i {
                &:before {
                    content: '-';
                }
            }
        }
        + a {
            display: inline-block;
            width: auto;
        }
    }
    .sub {
        //padding: 0 px2rem(40px);
        display: none;
        li {
            border-bottom: 1px solid #e2e2e2;
            padding-left: px2rem(50px);
            position: relative;
            height: px2rem(84px);
            overflow: hidden;
            a {
                display: block;
                line-height: px2rem(84px);
                font-size: px2rem(28px);
                height: px2rem(84px);
                color: #666;
                overflow: hidden;
            }
        }
    }
}
.article-swiper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    touch-action: none;
    z-index: 8888;
    .swiper-zoom-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
    }
    .swiper-pagination {
        bottom: 1em;
        color: #fff;
    }
    .swiper-slide {
        img {
            max-width: 95%;
            max-height: calc(100% - 6em);
            vertical-align: middle;
        }
        p {
            position: absolute;
            top: 1em;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            color: #fff;
            z-index: 22;
        }
    }
}
.audio-btn {
    position: fixed;
    font-size: 34px;
    line-height: 40px;
    color: #666;
    top: 35px;
    left: 30px;
    z-index: 888;
    &:before {
        content: '\e61b';
    }
    &.pause {
        &:before {
            content: '\e61a';
        }
    }
    .mejs__container {
        display: none;
    }
    @media screen and (max-width: 1700px) {
        left: 10px;
    }
    @media screen and (max-width: 1220px) {
        top: px2rem(120px);
        left: px2rem(30px);
        color: #fff;
        position: absolute;
    }
}
.video-dialog-con {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 993;
    //display: none;
    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .7);
    }
    .box {
        position: absolute;
        width: 1280px;
        height: 720px;
        max-width: 90%;
        max-height: 90%;
        background-color: #fff;
        z-index: 2;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        backface-visibility: hidden;
    }
    .close-btn {
        position: absolute;
        width: 30px;
        height: 30px;
        background-color: #fff;
        border-radius: 100%;
        box-shadow: 0 0 10px rgba(#000, .2);
        text-align: center;
        line-height: 30px;
        font-size: 14px;
        color: #333;
        right: -15px;
        top: -15px;
        cursor: pointer;
        z-index: 5;
    }
    .mejs__container,
    video {
        width: 100% !important;
        height: 100% !important;
    }
}
.anim-evt {
    opacity: 0;
    transition: all .5s linear;
    backface-visibility: hidden;
    &.anim-b2t {
        transform: translateY(50px);
    }
    &.anim-l2r {
        transform: translateX(-50px);
    }
    &.anim {
        opacity: 1;
        transform: translate(0, 0);
    }
}
.mobile-top {
    position: fixed;
    right: 5px;
    bottom: px2rem(50px);
    z-index: 201;
    width: px2rem(80px);
    height: px2rem(80px);
    text-align: center;
    line-height: px2rem(80px);
    display: none;
    align-items: center;
    justify-content: center;
    font-size: px2rem(40px) !important;
    color: #fff;
    background-color: $mainBlue;
    border-radius: 100%;
    @media screen and (max-width: 1024px) {
        display: flex;
    }
}