.about-con {
    background-color: #fff;
    padding: px2rem(80px) 0 px2rem(130px);
}
.about-top {
    display: flex;
    flex-direction: row-reverse;
    background-color: #e5e5e5;
    margin-bottom: px2rem(50px);
    @media screen and (max-width: 1200px) {
        display: block;
    }
}
.about-top-swiper {
    flex: 734;
    overflow: hidden;
    .swiper-slide {
        .img {
            position: relative;
            width: 100%;
            padding-top: (520/734) * 100%;
            overflow: hidden;
            @include imgP()
        }
    }
    .swiper-pagination {
        &-bullet {
            opacity: 1;
            background: transparent;
            border: 1px solid #fff;
            cursor: pointer;
            outline: none;
            &-active {
                background: #fff;
            }
        }
    }
}
.about-top-info {
    flex: 466;
    overflow: hidden;
    display: flex;
    align-items: center;
    .box {
        padding: 0 px2rem(40px) 0 px2rem(60px);
        .en {
            font-size: 40px;
            line-height: 44px;
            color: #333;
            margin-bottom: px2rem(20px);
            font-family: Arial;
            font-weight: bold;
            text-transform: uppercase;
        }
        .p {
            font-size: 22px;
            line-height: 45px;
            color: #333;
        }
    }
    @media screen and (max-width: 1200px) {
        .box {
            padding: px2rem(40px);
            box-sizing: border-box;
            width: 100%;
        }
    }
    @media screen and (max-width: 750px) {
        .box {
            .en {
                font-size: 24px;
                line-height: 30px;
            }
            .p {
                font-size: 16px;
                line-height: 30px;
            }
        }
    }
}
.about-article {
    color: #333;
    line-height: 35px;
    @include articleReset(18px, 35px);
    @media screen and (max-width: 750px) {
        font-size: 14px;
        line-height: 28px;
    }
}