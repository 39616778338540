.solution-banner {
    margin-top: px2rem(110px);
    img {
        display: block;
        width: 100%;
    }
    @media screen and (max-width: 1220px) {
        margin-top: 0;
    }
    @media screen and (max-width: 750px) {
        display: none;
    }
}
.solution-banner-mobile {
    position: relative;
    width: 100%;
    padding-top: (320/750) * 100%;
    overflow: hidden;
    display: none;
    margin-top: px2rem(100px);
    @include imgP();
    @media screen and (max-width: 750px) {
        display: block;
    }
}
.solution-section {
    background-color: #fff;
    padding-top: px2rem(70px);
    padding-bottom: px2rem(100px);
    &:nth-child(2n) {
        background-color: #f5f5f5;
    }
}
.solution-title {
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: #333;
    margin-bottom: px2rem(30px);
    &.color-blue {
        color: $mainBlue;
    }
    @media screen and (max-width: 750px) {
        font-size: 20px;
    }
}
.solution-icon-list {
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    font-size: 0;
    .item {
        display: inline-flex;
        vertical-align: top;
        width: 25%;
        box-sizing: border-box;
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        height: 215px;
        padding-right: px2rem(20px);
        padding-left: px2rem(20px);
        //&:nth-child(2n) {
        //    background-color: #f5f5f5;
        //}
        .icon {
            width: px2rem(100px);
            height: px2rem(100px);
            margin-bottom: px2rem(24px);
            transform-origin: center center;
            perspective: 800px;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .p {
            text-align: center;
            font-size: 20px;
            color: #333;
            @media screen and (max-width: 800px) {
                font-size: 16px;
                line-height: 26px;
                height: 52px;
            }
        }
        &:hover {
            @media screen and (min-width: 450px) {
                .icon {
                    img {
                        transition: all .5s ease;
                        transform: rotate3d(0, 1, 0, 360deg);
                    }
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        display: flex;
        flex-wrap: wrap;
        .item {
            height: auto;
            justify-content: start;
            padding: px2rem(40px) px2rem(20px);
            .p {
                font-size: 14px;
                line-height: 20px;
                height: auto;
            }
        }
    }
}
.solution-detail-list {
    border-top: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    font-size: 0;
    .item {
        display: inline-flex;
        vertical-align: top;
        width: (1/6) * 100%;
        box-sizing: border-box;
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        height: 270px;
        position: relative;
        &:hover {
            .hover {
                opacity: 1;
            }
        }
        @media screen and (max-width: 750px) {
            width: 33.3%;
        }
    }
    .icon {
        width: px2rem(100px);
        height: px2rem(100px);
        margin-bottom: px2rem(34px);
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .t {
        width: px2rem(100px);
        font-size: 18px;
        line-height: 26px;
        color: #333;
        text-align: center;
        @media screen and (max-width: 750px) {
            width: 100%;
            font-size: 16px;
            box-sizing: border-box;
            padding: 0 px2rem(20px);
            height: 52px;
        }
    }
    .hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: px2rem(30px);
        overflow: hidden;
        display: flex;
        align-items: center;
        background-color: $mainBlue;
        transition: all .2s ease;
        opacity: 0;
        .p {
            color: #fff;
            font-size: 14px;
            line-height: 24px;
        }
    }
}
.solution-big-list {
    display: flex;
    .item {
        flex: 1;
        position: relative;
        + .item {
            margin-left: px2rem(30px);
        }
        &:hover {
            .info {
                background-color: rgba($mainBlue, .9);
            }
            .p {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
    .img {
        width: 100%;
        height: 0;
        padding-top: (575/380) * 100%;
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
    .info {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .5);
        box-sizing: border-box;
        padding-top: px2rem(100px);
        padding-right: px2rem(50px);
        padding-left: px2rem(50px);
        overflow: hidden;
        text-align: center;
        color: #fff;
        transition: all .2s ease;
        z-index: 2;
    }
    .tip {
        font-size: 20px;
        margin-bottom: px2rem(20px);
    }
    .t {
        font-weight: bold;
        font-size: 24px;
    }
    .p {
        font-size: 16px;
        line-height: 30px;
        transition: all .2s ease;
        opacity: 0;
        transform: translateY(10px);
        backface-visibility: hidden;
        margin-top: px2rem(60px);
    }
    @media screen and (max-width: 800px) {
        .info {
            padding-top: px2rem(50px);
            padding-right: px2rem(30px);
            padding-left: px2rem(30px);
        }
        .tip {
            font-size: 16px;
        }
        .t {
            font-size: 20px;
        }
        .item {
            + .item {
                margin-left: px2rem(15px);
            }
        }
    }
    @media screen and (max-width: 400px) {
        .tip {
            font-size: 14px;
        }
        .t {
            font-size: 16px;
        }
    }
}
.solution-expert-swiper {
    position: relative;
    .swiper-slide {
        border: 1px solid #e5e5e5;
        background-color: #f5f5f5;
        box-sizing: border-box;
        padding: px2rem(50px) px2rem(40px) px2rem(10px);
        height: auto;
        .avatar {
            width: px2rem(180px);
            height: px2rem(180px);
            margin: 0 auto px2rem(30px);
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .name {
            text-align: center;
            font-size: 28px;
            color: #333;
            margin-bottom: px2rem(16px);
        }
        .p {
            font-size: 16px;
            line-height: 35px;
            color: #666;
        }
    }
    .expert-prev,
    .expert-next {
        position: absolute;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 100%;
        background-color: #999;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        outline: none;
        top: 50%;
        margin-top: -15px;
        transition: all .2s ease;
        z-index: 2;
        &:hover {
            background-color: $mainBlue;
        }
    }
    .expert-prev {
        left: -50px;
    }
    .expert-next {
        right: -50px;
        transform: rotate(180deg);
    }
    @media screen and (max-width: 1220px) {
        .expert-prev {
            left: px2rem(-30px);
        }
        .expert-next {
            right: px2rem(-30px);
        }
    }
    @media screen and (max-width: 750px) {
        .swiper-slide {
            .name {
                font-size: 18px;
            }
            .p {
                font-size: 14px;
                line-height: 28px;
            }
        }
    }
}
.solution-cell {
    display: flex;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    padding: px2rem(20px);
    .img {
        flex: 620;
        margin-right: px2rem(30px);
        overflow: hidden;
        img {
            display: block;
            width: 100%;
        }
    }
    .info {
        flex: 540;
        overflow: hidden;
        font-size: 18px;
        line-height: 30px;
        color: #333;
        padding-top: px2rem(20px);
        .p {
            margin-bottom: px2rem(20px);
            padding-left: 25px;
            background-image: url(../images/s_icon_36.png);
            background-repeat: no-repeat;
            background-position: 0 6px;
        }
    }
    @media screen and (max-width: 800px) {
        display: block;
        .img {
            margin-right: 0;
            margin-bottom: px2rem(30px);
        }
    }
}
.solution-process {
    ol {
        display: flex;
        li {
            flex: 1;
            margin-bottom: px2rem(100px);
            position: relative;
            &:after {
                position: absolute;
                width: 50px;
                height: 10px;
                background: url(../images/s_icon_35.png) no-repeat;
                content: '';
                top: 40px;
                right: -25px;
                display: block;
            }
            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
        &:nth-child(2n) {
            flex-direction: row-reverse;
            li {
                &:last-child {
                    &:after {
                        display: block;
                    }
                }
                &:first-child {
                    &:after {
                        transform: rotate(90deg);
                        right: auto;
                        left: 50%;
                        top: -40px;
                        margin-left: -25px;
                    }
                }
            }
        }
    }
    .icon {
        width: px2rem(100px);
        height: px2rem(100px);
        margin: 0 auto 10px;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .p {
        text-align: center;
        font-size: 18px;
        line-height: 30px;
        color: #333;
    }
    @media screen and (max-width: 800px) {
        ol {
            flex-wrap: wrap;
            li {
                flex: 0 0 33.33%;
                &:nth-child(3n+3) {
                    &:after {
                        display: none;
                    }
                }
            }
            &:nth-child(2n) {
                flex-direction: row;
                li {
                    &:first-child {
                        &:after {
                            left: auto;
                            position: absolute;
                            width: 50px;
                            height: 10px;
                            top: 40px;
                            right: -25px;
                            display: block;
                            transform: rotate(0);
                        }
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        ol {
            li {
                margin-bottom: px2rem(50px);
                &:after {
                    display: none !important;
                }
            }
        }
        .p {
            font-size: 14px;
        }
    }
}