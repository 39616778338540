.about-history-menu {
    margin-top: 4px;
    background: #fff url(images/history_1.png) center repeat-x;
    background-size: auto 100%;
    text-align: center;
    &-year {
        display: inline-block;
        vertical-align: top;
        width: 99px;
        height: 90px;
        margin: 0 15px;
        line-height: 90px;
        font-size: 18px;
        color: #4c4c4c;
        text-align: center;
        cursor: pointer;
        font-weight: bold;
        overflow: hidden;
        &.on {
            color: #fff;
            background: url(images/history_2.png) center no-repeat;
            background-size: auto 100%;
            &:hover {
                color: #fff;
            }
        }
        &:hover {
            color: $mainBlue;
        }
    }
    @media screen and (max-width: 800px) {
        display: flex;
        justify-content: center;
        &-year {
            flex: 1;
            max-width: 99px;
            margin: 0 px2rem(15px);
            font-size: px2rem(24px);
        }
    }
}
.about-history-con {
    padding: px2rem(70px) 0 px2rem(130px);
}
.about-history-title {
    text-align: center;
    margin-bottom: 10px;
    dt {
        font-size: px2rem(50px);
        font-family: "Arial";
        color: $mainBlue;
        font-weight: bold;
    }
    dd {
        font-size: 16px;
        color: #343434;
        letter-spacing: 4px;
    }
}
.about-history-box {
    position: relative;
    overflow: hidden;
}
.about-history {
    position: relative;
    z-index: 2;
    min-height: px2rem(374px);
    padding-bottom: px2rem(60px);
    width: 1060px;
    max-width: 100%;
    margin: 0 auto;
    .img {
        position: absolute;
        display: block;
        width: px2rem(210px);
        height: px2rem(216px);
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 5;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .line {
        position: absolute;
        width: 3px;
        top: px2rem(100px);
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        background: #e5f1fd;
        z-index: 3;
    }
    .dot {
        position: absolute;
        padding: 5px;
        border-radius: 50%;
        border: 1px dashed $mainBlue;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 10px;
        height: 10px;
        z-index: 3;
        span {
            display: inline-block;
            vertical-align: top;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $mainBlue;
        }
    }
    ul {
        position: relative;
        padding-top: px2rem(290px);
        z-index: 4;
        li {
            .info {
                width: 50%;
                position: relative;
                box-sizing: border-box;
                &:before {
                    position: absolute;
                    display: block;
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: $mainBlue;
                    top: 50%;
                    margin-top: -5px;
                    z-index: 6;
                }
                .box {
                    position: relative;
                    padding: px2rem(20px) px2rem(26px);
                    border-radius: 6px;
                    background-color: #f1f1f1;
                }
                h5 {
                    position: relative;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000;
                    margin-bottom: 10px;
                }
                .p {
                    display: inline-block;
                    vertical-align: top;
                    font-size: 18px;
                    line-height: 30px;
                    color: #434343;
                    width: 100%;
                    @media screen and (max-width: 750px) {
                        font-size: 14px;
                        line-height: 26px;
                    }
                }
            }
            &:nth-child(even) {
                .info {
                    float: left;
                    padding-right: px2rem(25px);
                    &:before,
                    &:after {
                        right: -5px;
                    }
                    .box {
                        &:before {
                            position: absolute;
                            width: 0;
                            height: 0;
                            content: '';
                            display: block;
                            border-left: 10px solid #f1f1f1;
                            border-top: 8px solid transparent;
                            border-bottom: 8px solid transparent;
                            right: -8px;
                            top: 50%;
                            margin-top: -8px;
                        }
                    }
                }
            }
            &:nth-child(odd) {
                .info {
                    float: right;
                    padding-left: px2rem(25px);
                    &:before,
                    &:after {
                        left: -5px;
                    }
                    .box {
                        &:before {
                            position: absolute;
                            width: 0;
                            height: 0;
                            content: '';
                            display: block;
                            border-right: 10px solid #f1f1f1;
                            border-top: 8px solid transparent;
                            border-bottom: 8px solid transparent;
                            left: -8px;
                            top: 50%;
                            margin-top: -8px;
                        }
                    }
                }
            }
        }
    }
}