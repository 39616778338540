.contact-detail {
    display: flex;
    margin-bottom: px2rem(20px);
    justify-content: space-between;
    .info {
        flex: 1;
        overflow: hidden;
        font-size: 18px;
        line-height: 30px;
        color: #666;
        p {
            margin-bottom: 8px;
        }
    }
    .ewm {
        width: 200px;
        height: 200px;
        //padding: 10px;
        border: 1px solid #e5e5e5;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    @media screen and (max-width: 750px) {
        display: block;
        .info {
            font-size: 14px;
        }
        .ewm {
            margin: 0 auto;
        }
    }
}
.contact-map {
    padding: 10px;
    border: 1px solid #e5e5e5;
    .map {
        width: 100%;
        height: px2rem(480px);
        .BMap_bubble_title {
            font-weight: bold;
            font-size: 14px;
        }
    }
}